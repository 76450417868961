import { computed } from 'vue';
import { store } from '@/store/index';
import { readCompany } from '@/store/main/getters';
import { defineMessages } from '@/lib/i18n';
import { i18n } from '@/plugins/vue-i18n';

const $t = i18n.t.bind(i18n);

const m = defineMessages({
  inactiveMessage:
    '「{feature}」を追加購入いただいたお客様がご利用になれる機能です。\nお求めの場合は、ケミカンメニューバー右上の「お問い合わせ」からお問い合わせください。',
});

const COMPANY_FEATURES = {
  MANAGE_IP_ADDRESS: 'MANAGE_IP_ADDRESS',
  MANAGE_USER_PERMISSION: 'MANAGE_USER_PERMISSION',
  CREATE_CHECKLIST: 'CREATE_CHECKLIST',
  MANAGE_SMS_AUTHENTICATION: 'MANAGE_SMS_AUTHENTICATION',
} as const;
export type CompanyFeature =
  (typeof COMPANY_FEATURES)[keyof typeof COMPANY_FEATURES];

const generateInactiveMessage = (feature: string) => {
  return $t(m.inactiveMessage, { feature });
};

const EXCLUDE_CHECKLISTS = [
  '毒物及び劇物取締法の規制対象物質を含む可能性のあるSDS',
  '安衛法：ラベル表示・SDS交付の義務化対象物質（リスクアセスメント対象物質）を含む可能性のあるSDS',
  '安衛法：皮膚等障害化学物質（労働安全衛生規則第594条の２（令和６年４月１日施行））及び特別規則に基づく不浸透性の保護具等の使用義務物質を含む可能性のあるSDS',
  '安衛法：濃度基準値が設定されている対象物質を含む可能性のあるSDSのリスト（2025年10月施行分を含む）',
  '安衛法：濃度基準値が設定されている対象物質を含む可能性のあるSDSのリスト',
  '安衛法：強い変異原性が認められた化学物質を含む可能性のあるSDS',
  '安衛法：「がん原性物質」及び「がん原性に係る指針対象物質に該当する物質」を含む可能性のあるSDS',
  '化管法対象物質：令和４年度分までの排出量等の把握や令和４年度末までのSDS提供の対象',
  '化管法対象物質：令和５年度分以降の排出量等の把握や令和５年度以降のSDS提供の対象',
  'SDSの最終更新日・改訂日（作成日しか記載がない場合は作成日）から5年以上経過している可能性のあるSDS',
  'REACH規則 SVHC対象物質（第32次まで）を含む可能性のあるSDS',
] as const;

export const useCompanyFeatureComposable = () => {
  const companyActiveFeatures = computed(() => {
    return readCompany(store).active_features?.map((f) => f.id);
  });

  const isFeatureActive = (required: CompanyFeature) => {
    return companyActiveFeatures.value?.includes(required);
  };

  return {
    isFeatureActive,
    COMPANY_FEATURES,
    generateInactiveMessage,
    EXCLUDE_CHECKLISTS,
  };
};
